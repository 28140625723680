export class UrlUtils {
  // private static readonly MriClientIdQueryParameter = 'mri_client_id';
  // private static readonly MriUserEmailQueryParameter = 'login_hint';
  private static readonly ClientIdReplacementToken = '${mri_client_id}';
  private static readonly EmailReplacementToken = '${mri_user_email}';
  /**
   * Trim any whitespace and add https:// if not present to a string.
   * Does not check actual validity of the url.
   * @param url
   * @returns
   */
  static formatUrl(url: string) {
    if (this.isStringEmpty(url)) return '';
    if (url.search('http://') === -1 && url.search('https://') === -1) {
      url = 'https://' + url;
    }
    url = url.trim();
    return url;
  }

  /**
   * Replace client ID and email tokens in a url with actual values.
   * @param url Url to replace the tokens of
   * @param clientId Client Id to insert in place of the token
   * @param email Email to insert in place of the token
   * @returns Interpreted url with tokens replaced
   */
  static substituteUrlTokens(url: string, clientId: string | undefined, email: string | undefined): string {
    if (this.isStringEmpty(url)) return '';
    url = url!;
    if (!this.isStringEmpty(clientId) && url.includes(this.ClientIdReplacementToken)) {
      url = url.replace(this.ClientIdReplacementToken, clientId!);
    }

    if (!this.isStringEmpty(email) && url.includes(this.EmailReplacementToken)) {
      url = url.replace(this.EmailReplacementToken, email!);
    }

    return url;
  }

  /**
   * Given a base and an extension/relative path, construct a new url, preserving query parameters.
   * @param base Base of url, which can contain query parameters and paths
   * @param path Path to append to base. Can be a relative path
   * @returns
   */
  static constructUrlWithExtension(base: string | null | undefined, path: string | null | undefined) {
    if (this.isStringEmpty(base)) return '';
    base = base!;
    if (this.isStringEmpty(path)) return base;
    path = path!;
    const getParamsFromURI = (uri: string) => {
      // Get everything after the `?`
      const [, paramString] = uri.split('?');
      return new URLSearchParams(paramString);
    };

    const baseUrl = new URL(UrlUtils.formatUrl(base));
    try {
      const params = getParamsFromURI(baseUrl.href);
      const extension = new URL(path, baseUrl);
      extension.search = params.toString();
      return extension.toString();
    } catch {
      return base.toString();
    }
  }

  static isStringEmpty(url: string | null | undefined): boolean {
    if (!url) return true;
    if (!(url.length > 0)) return true;
    if (/\S/.test(url)) return false;
    return false;
  }
}
