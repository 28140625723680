export enum EntityName {
  Application = 'Application',
  ApplicationUser = 'ApplicationUser',
  Client = 'Client',
  ClientFeature = 'ClientFeature',
  Message = 'Announcement', // sigh
  Partner = 'Partner',
  Portal = 'Portal',
  SelfHosted = 'SelfHosted',
  User = 'User'
}
