import { RouteInfo } from '@mri-platform/shared/core';

export const AppRoutes = {
  Applications: RouteInfo.path('ag-admin-applications').children({
    Detail: RouteInfo.detailPage()
  }),
  ApplicationUserMatrix: RouteInfo.path('ag-admin-application-user-matrix'),
  Users: RouteInfo.path('ag-admin-users').children({
    Detail: RouteInfo.detailPage()
  }),
  UserManagement: RouteInfo.path('ag-admin-user-management'),
  Messages: RouteInfo.path('ag-admin-messages').children({
    Detail: RouteInfo.detailPage()
  }),
  ClientSettings: RouteInfo.path('ag-admin-client-settings').children({
    HomePage: RouteInfo.path('home-page').children({
      LogoSelection: RouteInfo.path('logo')
    })
  })
};
